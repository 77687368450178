import { useElementRef } from '@/lib/composables/utils/useElementRef';
import { IFormatNumberOptions } from '@/lib/form';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { createComponent, watchRun } from '@/lib/vue';
import { IPieChartData } from '@/typings';
import { onMounted } from '@vue/composition-api';
import {
  ArcElement,
  Chart,
  DoughnutController,
  Legend,
  PieController,
  Tooltip,
} from 'chart.js';
import { PropType } from 'vue';

Chart.register(PieController, DoughnutController, Legend, Tooltip, ArcElement);

export type IPieChartType = 'pie' | 'doughnut';
type IPieChart = Chart<IPieChartType, number[], string>;

interface IProps {
  value: readonly IPieChartData[];
  type?: IPieChartType;
  colors?: string[];
  semi?: boolean;
  size?: number;
  tooltipFormat?: IFormatNumberOptions;
}

export const PieChart = createComponent<IProps>({
  name: 'PieChart',
  props: {
    value: { type: Array as PropType<IPieChartData[]>, required: true },
    type: { type: String as PropType<IPieChartType>, default: 'doughnut' },
    colors: Array as PropType<string[]>,
    semi: { type: Boolean, default: false },
    size: { type: Number, default: 380 },
    tooltipFormat: Object as PropType<IFormatNumberOptions>,
  },
  setup(props, ctx) {
    let chart: IPieChart | null = null;

    const { elementId: canvasId, getElement: getCanvas } =
      useElementRef<HTMLCanvasElement>();

    onMounted(() => {
      const canvasEl = getCanvas();

      if (!canvasEl) throw Error('canvas element not found');

      chart = createChart({ props, canvasEl });
    });

    watchRun(
      () => props.value,
      () => updateChart({ chart, props }),
    );

    return () => (
      <div
        id="PieChart"
        style={{
          height: `${props.size}px`,
          width: `${props.size}px`,
          maxHeight: `${props.size}px`,
          maxWidth: `${props.size}px`,
        }}
      >
        <canvas id={canvasId} />
      </div>
    );
  },
});

function createChart({
  props,
  canvasEl,
}: {
  props: IProps;
  canvasEl: HTMLCanvasElement;
}): IPieChart {
  return new Chart(canvasEl.getContext('2d')!, {
    type: props.type || 'doughnut',
    data: {
      labels: props.value.map(v => v.label),
      datasets: [
        {
          data: props.value.map(v => v.value),
          backgroundColor: mapColors(props),
        },
      ],
    },
    options: mapOptions(props),
  });
}

function updateChart({
  chart,
  props,
}: {
  chart: IPieChart | null;
  props: IProps;
}) {
  if (!chart) return;

  chart.data.labels = props.value.map(v => v.label);

  if (chart.data.datasets) {
    const dataset = chart.data.datasets[0];

    dataset.data = props.value.map(v => v.value);

    dataset.backgroundColor = mapColors(props);
  }

  chart.options = mapOptions(props);

  chart.update();
}

function mapOptions(props: IProps): IPieChart['options'] {
  return {
    locale: 'pt-BR',
    plugins: {
      legend: {
        display: props.value.length <= 5,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: ChartHelpers.tooltipLabelCb({
            format: props.tooltipFormat,
            type: 'pie',
          }),
        },
      },
    },
    circumference: props.semi ? 180 : 360,
    rotation: props.semi ? -90 : 0,
    font: { family: '"Roboto", sans-serif' },
  };
}

function mapColors(props: IProps) {
  const dataColors = props.value.map(v => v.color).filter(Boolean);

  return dataColors.length ? dataColors : props.colors;
}
