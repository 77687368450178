import { IChartData } from '@/typings';
import { TooltipItem } from 'chart.js';
import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';
import { ChartColorPalletes } from '../constants/color';
import { formatNumber, IFormatNumberOptions } from '../form';
import { DateHelpers, IDateTypes } from './date.helpers';

export const ChartHelpers = {
  line: {
    mapDates({
      startDate,
      endDate,
      data,
      group = true,
      maxDays = 60,
    }: {
      startDate: IDateTypes;
      endDate: IDateTypes;
      data: IChartData[];
      group?: boolean;
      maxDays?: number;
    }): IChartData[] {
      const days = DateHelpers.dateDiff(startDate, endDate, 'days')?.days || 0;

      if (!days) return [];

      const fomattedData = data.map(v => ({
        label: DateHelpers.formatDate(v.label)!,
        value: v.value,
      }));

      const allDays = Array(days)
        .fill(0)
        .map((_, i) => {
          const date = DateHelpers.dateAdd(startDate, { days: i });

          const label = DateHelpers.formatDate(date)!;
          const month = DateHelpers.formatMonthAndYear(date)!;

          return {
            label,
            value: fomattedData.find(f => f.label === label)?.value || 0,
            month,
          };
        });

      if (group && days <= maxDays) return allDays;

      const objMonths = allDays.reduce<{ [key: string]: number }>(
        (obj, curr) => ({
          ...obj,
          [curr.month]: (obj[curr.month] || 0) + curr.value,
        }),
        {},
      );

      return Object.entries(objMonths).map(([label, value]) => ({
        label,
        value,
      }));
    },

    mapIntegers(data: IChartData[]): IChartData[] {
      if (!data.length) return [];

      const numbers = data.map(v => Number(v.label));

      const max = Math.max(...numbers) + 3;

      const min = Math.min(...numbers) - 2;

      return Array(max - min)
        .fill(0)
        .map((_, i) => {
          const label = (min + i).toString();

          return {
            label,
            value: data.find(f => f.label === label)?.value || 0,
          };
        });
    },
  },

  pie: {
    colors() {
      return shuffle(sample(Object.values(ChartColorPalletes)));
    },
  },

  tooltipLabelCb({
    format,
    type,
  }: {
    format: IFormatNumberOptions | undefined;
    type: 'line' | 'pie' | 'doughnut';
  }) {
    return (
      item: TooltipItem<'line' | 'pie' | 'doughnut'>,
    ): string | string[] => {
      const label = (type === 'line' ? item.dataset.label : item.label) || '';
      const rawValue = (item.raw as number) || 0;

      const formattedValue = format
        ? formatNumber(rawValue, format)
        : item.formattedValue;

      return `${label}: ${formattedValue}`;
    };
  },
};
